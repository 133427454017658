<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
    v-model="isUserMenuOpen"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed v-bind="attrs" v-on="on" text class="user-menu-open-button">
        <span>{{ userName }}</span>
        <v-icon class="ml-6" color="primary">
          {{ isUserMenuOpen ? icons.mdiChevronUp : icons.mdiChevronDown }}
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <!-- Logout -->
      <v-list-item link>
        <v-list-item-icon class="me-2">
          <v-icon size="22" color="error">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content @click="logout">
          <v-list-item-title>登出</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiChevronDown,
  mdiChevronUp,
} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiChevronDown,
        mdiChevronUp,
      },
      userName: 'User',
      isUserMenuOpen: false,
    }
  },
  // functions
  methods: {
    async logout() {
      const userData = this.getCurrentUserData()
      if (this.$validate.DataValid(userData)) {
        await this.clearVerifyToken(userData.id)
      }

      this.$router.replace({ name: 'login' })
    },
    getLoginData() {
      const userData = this.getCurrentUserData()

      this.userName = userData.username
    },
  },
  created() {
    this.getLoginData()
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
