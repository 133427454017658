export const cannotAccessList = {
  'AdminList': ['manager', 'accountant', 'admin'],
  'AdminDetail': ['manager', 'accountant', 'admin'],
  'CreateAdmin': ['manager', 'accountant', 'admin'],
  'adminStaff': ['manager', 'admin'],
  'adminStaffDetail': ['manager', 'admin'],
  'adminStaffCreate': ['manager', 'admin'],
  'NewsList': ['manager', 'accountant'],
  'NewsDetail': ['manager', 'accountant'],
  'CreateNews': ['manager', 'accountant'],
  'adminClaim': ['admin'],
  'adminClaimDetail': ['admin'],
  'adminClaimCreate': ['admin'],
  'adminLeave': ['accountant', 'admin'],
  'adminLeaveDetail': ['accountant', 'admin'],
  'adminLeaveCreate': ['accountant', 'admin'],
  'CheckInList': ['manager', 'accountant'],
  'adminSalary': ['manager', 'admin'],
  'adminSalaryCreate': ['manager', 'admin'],
  'adminSalaryDetail': ['manager', 'admin'],

}

export const defaultPage = {
  developer: '/admin',
  master: '/admin',
  manager: '/claim',
  accountant: '/adminStaff',
  admin: '/news'
}
