<template>
  <!-- :is 可根據傳入參數的不同，而去切換不同的元件 -->

  <component :is="resolveLayout">
    <router-view></router-view>
    <UploadAlertBox :progress="uploadProgress" :show="showUploadAlert"></UploadAlertBox>
    <LoadingDialog :load="showLoadingPage"></LoadingDialog>
    <AlertBox :type="alertType" :text="alertText" :show="showAlert"></AlertBox>
    <v-overlay :value="showOverlay" :z-index="8"></v-overlay>
    <SubmitDialog></SubmitDialog>
  </component>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import UploadAlertBox from '@/components/UploadAlertBox'
import LoadingDialog from '@/components/LoadingDialog'
import AlertBox from '@/components/AlertBox'
import SubmitDialog from './components/SubmitDialog.vue'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
    UploadAlertBox,
    LoadingDialog,
    AlertBox,
    SubmitDialog
  },
  setup() {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      // https://vuejs.org/v2/guide/computed.html#Computed-Properties
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
  computed: {
    showLoadingPage() {
      return this.$store.getters.showLoadingPage
    },
    alertType() {
      return this.$store.getters.alertMessage.type
    },
    alertText() {
      return this.$store.getters.alertMessage.message
    },
    showAlert() {
      return this.$store.getters.alertMessage.show
    },
    showUploadAlert() {
      return this.$store.getters.showUploadAlert
    },
    uploadProgress() {
      return this.$store.getters.uploadProgress
    },
    showOverlay() {
      return this.$store.getters.showOverlay
    },
  },
}
</script>
