<template>
  <div>
    <v-row v-if="showTopRow || $route.meta.showTitleAtSlot">
      <v-col class="d-flex align-center justify-space-between flex-wrap">
        <div class="d-flex align-center">
          <!--           <v-btn depressed icon class="mr-5" @click="$router.back()" v-if="showMiniBackButton">
            <v-icon class="fi fi-rr-angle-square-left black--text" style="font-size: 18px"></v-icon>
          </v-btn> -->
          <div class="page-title" v-if="this.$validate.DataValid(pageTitle)">{{ pageTitle }}</div>
          <slot name="action-slot"></slot>
        </div>
        <div class="top-right-class">
          <slot name="top-right-slot"></slot>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="showBottomLeftAndRightRow">
      <v-col class="d-flex align-center justify-space-between flex-wrap">
        <div class="bottom-left-class">
          <v-btn depressed color="black" text @click="$router.back()" v-if="showBackButton" class="mr-2" :disabled="isLoading">
            <v-icon left class="back-button-icon">{{ mdiChevronLeft }}</v-icon>
            上一頁
          </v-btn>
          <slot name="bottom-left-slot"></slot>
        </div>
        <div class="bottom-right-class">
          <slot name="bottom-right-slot"></slot>
        </div>
      </v-col>
    </v-row>
    <slot v-if="showBottomRow" name="bottom-slot"></slot>
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
export default {
  name: 'PageHeaderSlot',
  props: {
    /*     showMiniBackButton: {
      require: false,
      type: Boolean,
      default: false,
    }, */
    showBackButton: {
      require: false,
      type: Boolean,
      default: false,
    },
    isLoading: {
      require: false,
      type: Boolean,
      default: false,
    },
    showBottomLeftAndRightRow: {
      require: false,
      type: Boolean,
      default: true,
    },
    showBottomRow: {
      require: false,
      type: Boolean,
      default: false,
    },
    showTopRow: {
      require: false,
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mdiChevronLeft,
  }),
  computed: {
    pageTitle: function () {
      let result = ''

      if (this.$route.meta.title !== undefined && this.$route.meta.showTitleAtSlot) {
        result = this.$route.meta.title
      }

      return result
    },
  },
}
</script>

<style lang="scss" scoped>

.back-button-icon {
  font-size: 14px !important;
}

.top-right-class {
  /* d-flex align-center my-2 ml-2*/
  display: flex;
  align-content: center;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 8px;
}

.bottom-left-class {
  //d-flex align-center
  display: flex;
  align-content: center;
}

.bottom-right-class {
  // d-flex align-center
  display: flex;
  align-content: center;
}
</style>