const SET_DIALOG = 'SET_DIALOG';
const SHOW_DIALOG = 'SHOW_DIALOG';

export default {
   state: {
      message: "",
      isError: false,
      showDialog: false,
      returnLink: null,
   },
   actions: {
      setDialogMessage: ({ commit }, { message, isError, returnLink }) => {
         commit(SET_DIALOG, { message, isError, returnLink });
      },

      setShowDialog: ({ commit }, value) => {
         commit(SHOW_DIALOG, value);
      }
   },
   mutations: {
      [SET_DIALOG]: (state, { message, isError, returnLink }) => {
         state.message = message;
         state.isError = isError;
         state.returnLink = returnLink;
      },
      [SHOW_DIALOG]: (state, value) => {
         state.showDialog = value;
      },
   },
}
