/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-promise-reject-errors */
// Data formatter

export default {
  install(Vue, options) {
    Vue.prototype.$Formatter = {
      dayFormat(e) {
        return parseInt(e.substring(8, 10), 10).toString()
      },
      displayWeekday(d) {
        const weekday = ['日', '一', '二', '三', '四', '五', '六']
        if (d.length > 10) {
          return weekday[new Date(`${d.split('T')[0]}T12:00:00+08:00`).getDay()]
        }

        return weekday[new Date(`${d}T12:00:00+08:00`).getDay()]
      },
    }
  },
}
